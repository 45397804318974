import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import Dashboard from "@uppy/dashboard";
import "@uppy/dashboard/dist/style.css";

export default function clientLogoUpload(el, props) {
  const imageUrlInput = el.querySelector("#client-logo-url-input");

  const profileImageUploader = Uppy({
    id: "client-logo-image-uploader",
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      allowedFileTypes: [".jpg", ".jpeg", ".png"],
      maxFileSize: 20 * 1024 * 1024, // 20 MB
      maxNumberOfFiles: 1,
    },
  });

  profileImageUploader.use(Dashboard, {
    id: "client-logo-image-dashboard",
    target: "#client-logo-image-dashboard",
    inline: true,
    width: "100%",
    height: 400,
    thumbnailWidth: 280,
    doneButtonHandler: null, // Hide the 'Done' button, since clicking it removes the uploaded image
  });

  profileImageUploader.use(AwsS3Multipart, {
    limit: 4,
    companionUrl: "/",
  });

  profileImageUploader.on("upload-success", (file, response) => {
    imageUrlInput.value = response.uploadURL;
  });

  profileImageUploader.on("upload-error", (file, error, response) => {
    console.log("error with file:", file.id);
    console.log("error message:", error);
  });
}
