export default function alert(el, props) {
  let $el = $(el);

  const dismissButton = el.querySelector('[data-dismiss="alert"]');

  function onDismissButtonClick(e) {
    e.preventDefault();
    $el.alert("close");
  }

  // If the dismissButton isn't clicked, dismiss the alert after 5 seconds
  setTimeout(function () {
    $el.alert("close");
  }, 5000);

  dismissButton.addEventListener("click", onDismissButtonClick);

  function destroy() {
    dismissButton.addEventListener("click", onDismissButtonClick);
  }

  return {
    destroy: destroy,
  };
}
