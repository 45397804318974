export default function publicReportLinkModal(el, props) {
  const copyToClipboardButton = el.querySelector(".clipboard-action");
  const secretLinkInput = el.querySelector("#secretLink");

  function onCopyToClipboard(e) {
    e.preventDefault();
    secretLinkInput.select();
    document.execCommand("copy");
  }

  function onSecretLinkInputClick() {
    secretLinkInput.select();
  }

  copyToClipboardButton.addEventListener("click", onCopyToClipboard);
  secretLinkInput.addEventListener("click", onSecretLinkInputClick);

  function destroy() {
    copyToClipboardButton.removeEventListener("click", onCopyToClipboard);
    secretLinkInput.removeEventListener("click", onSecretLinkInputClick);
  }

  return {
    destroy: destroy,
  };
}
