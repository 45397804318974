import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import Dashboard from "@uppy/dashboard";
import "@uppy/dashboard/dist/style.css";

export default function quickCoverageItemImageUpload(el, props) {
  const imageUrlInput = el.querySelector(
    "#quick-coverage-item-image-url-input",
  );

  const quickCoverageItemImageUploader = Uppy({
    id: "quick-coverage-item-image-uploader",
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      allowedFileTypes: [".jpg", ".jpeg", ".png"],
      maxFileSize: 20 * 1024 * 1024, // 20 MB
      maxNumberOfFiles: 1,
    },
  });

  quickCoverageItemImageUploader.use(Dashboard, {
    id: "quick-coverage-item-image-dashboard",
    target: "#quick-coverage-item-image-dashboard",
    inline: true,
    width: "100%",
    height: 400,
    thumbnailWidth: 280,
    doneButtonHandler: null, // Hide the 'Done' button, since clicking it removes the uploaded image
  });

  quickCoverageItemImageUploader.use(AwsS3Multipart, {
    limit: 4,
    companionUrl: "/",
  });

  quickCoverageItemImageUploader.on("upload-success", (file, response) => {
    imageUrlInput.value = response.uploadURL;
  });

  quickCoverageItemImageUploader.on("upload-error", (file, error, response) => {
    console.log("error with file:", file.id);
    console.log("error message:", error);
  });
}
