export default function addAutomatedCoverageModal(el, props) {
  let $el = $(el);

  const form = el.querySelector("form");
  const urlsErrors = el.querySelector("#urls-errors");

  // Handle modal hide event
  $el.on("hidden.bs.modal", function () {
    // Reset form and clear errors
    form.reset();
    urlsErrors.innerHTML = "";
  });

  function destroy() {
    $el.off("hidden.bs.modal");
  }

  return {
    destroy: destroy,
  };
}
